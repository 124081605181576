import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  blockquote: {
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px 0`,
    padding: `0 0 0 ${theme.spacing(4)}px`,
    borderLeft: `3px solid ${theme.palette.grey[300]}`,
  },
}));

const Blockquote = ({ children }) => {
  const classes = useStyles();

  return <blockquote className={classes.blockquote}>{children}</blockquote>;
};

Blockquote.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Blockquote;
