import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Markdown from 'react-markdown';
import ReactMoment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Link as MuiLink } from '@material-ui/core';

import { withLayout } from '../components/Layout';
import SEO from '../components/SEO';
import Prose from '../components/Prose';
import Blockquote from '../components/Blockquote';
import ShareButtons from '../components/ShareButtons';

const ArticleHeading = ({ level, children }) => {
  switch (level) {
    case 1:
      return <Typography variant="h2">{children}</Typography>;
    case 2:
      return <Typography variant="h3">{children}</Typography>;
    case 3:
      return <Typography variant="h4">{children}</Typography>;
    default:
      return <Typography variant="h5">{children}</Typography>;
  }
};

ArticleHeading.propTypes = {
  level: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

const markdownRenderers = {
  heading: ArticleHeading,
  link: MuiLink,
  blockquote: Blockquote,
};

const useStyles = makeStyles((theme) => ({
  cover: ({ imageCover }) => ({
    color: theme.palette.text.white,
    textShadow: '1px 1px 10px rgba(0, 0, 0, 0.25)',
    background: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${imageCover})`,
    backgroundSize: 'cover, cover',
    backgroundPosition: 'center, center',
    padding: `${theme.spacing(24)}px ${theme.spacing(4)}px`,
    marginTop: theme.spacing(-6),
    marginBottom: theme.spacing(4),
    marginLeft: -1 * theme.spacing(2),
    marginRight: -1 * theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: -1 * theme.spacing(3),
      marginRight: -1 * theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-2),
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: theme.spacing(8),
    },
  }),
  authorLink: {
    color: theme.palette.text.white,
  },
}));

const Page = ({ pageContext }) => {
  const { article } = pageContext;
  const classes = useStyles(article);

  return (
    <>
      <SEO
        type="article"
        cardType="summary_large_image"
        title={article.title}
        description={`Article by ${article.author.name} at River of Life Christian Fellowship in Loveland, Colorado.`}
        image={article.imageUnfurl}
      />
      <Box className={classes.cover}>
        <Typography variant="subtitle2">
          <ReactMoment format="MMMM D, YYYY">{article.date}</ReactMoment>
        </Typography>
        <Typography variant="h1">{article.title}</Typography>
        <Typography variant="subtitle1">
          <MuiLink
            component={Link}
            to={`/speakers/${article.author.slug}`}
            className={classes.authorLink}
          >
            {article.author.name}
          </MuiLink>
        </Typography>
      </Box>

      <Prose>
        <Markdown components={markdownRenderers}>{article.body}</Markdown>
      </Prose>

      <ShareButtons
        title={`${article.title} by ${article.author.name}`}
        align="center"
      />
    </>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default withLayout(Page, { container: 'md' });
